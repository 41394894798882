

.picture-in-picture__footer {
  align-items: center;

  button > a {
    display: flex;

    align-items: center;
  }
}

/* reacted user list */
.reactions-bar__item {
  &__users {
    &__item {
      .display-name {
        min-width: 80px;
        display: block !important;

        img {
          display: inline !important;
          width: auto !important;
          height: 1em;
        }
      }
    }
  }
}

/* edit for rule style */
.about__section {
  &__title {
    padding: 12px 16px !important;
  }

  &__body {
    padding: 12px 16px !important;
    .rules-list {
      li {
        position: relative;
        vertical-align: middle;

        &::before {
          font-size: 16px;
          width: 2.0rem;
          height: 2.0rem;
        }
      }
    }
  }
}

body.system-font {
  .eorzea-clock,
  .eorzea-clock * {
    font-family: "FFXIV_Lodestone_SSF",system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,"mastodon-font-sans-serif",sans-serif !important
  }
}

#account_note
{
  min-height: 150px;
}

.empty-column-indicator
{
  display: flex;
  flex-direction: column;

  .sharlayan-badge
  {
    &__error,
    &__info,
    &__not-found
    {
      display: block;
      width: 128px;
      height: 128px;
      background-size: 100% 100%;
      background-position: 50% 50%;
      background-color: transparent;
      background-repeat: no-repeat;
      margin: 0 auto;
      margin-bottom: 16px;
      border-radius: 16px;
    }

    &__error
    {
      background-image: url('~images/error.png');
    }

    &__info
    {
      background-image: url('~images/info.png');
    }

    &__not-found
    {
      background-image: url('~images/not-found.png');
    }
  }
}

body.modal-layout.compose-standalone
{
  background: #000;
  &:before
  {
    content: "";
    position: fixed;
    z-index: -1;
    left: 0;
    top: 0;
    opacity: .25;
    display: block;
    width: 100vw;
    height: 100vh;
    background: url('~images/illust.png');
    background-position: 50% 20%
  }
}

.status,
.detailed-status,
.notification {
  img.emojione,
  img.custom-emoji {
    width: auto !important;
    max-width: 100px !important;
    position: relative;
    display: inline-block;
  }
}

.reactions-bar__item__users {
	.reactions-bar__item__emoji {
		span > img.custom-emoji {
			width: 180px !important;
			max-height: 120px !important;
		}
	}
}

span.reactions-bar__item__emoji {
	width: auto;
	align-items: center;

	img.emojione,
	img.custom-emoji {
		height: 20px;
	}
}

html {
	body {
		.doodle-modal {
			flex-direction: row;
			color: $gh-extra-black;

			.doodle-modal__action-bar {
				background-color: #EFEFEF;
				flex-direction: column-reverse;
				gap: 16px;

				.doodle-toolbar {
					width: calc(100% - 8px);
					align-items: start;
					padding: 0;

					button.inverted {
						color: $gh-extra-black;
						svg > path {
							fill: $gh-extra-black;
						}
					}

					&.with-inputs {
						gap: 4px
					}

					button.inverted.active {
						color: $gh-magenta;
						svg > path {
							fill: $gh-magenta;
						}
					}
				}

				.doodle-palette {
					display: flex;
					flex-wrap: wrap;
					max-width: 176px;
				}

				.doodle-toolbar:first-child {
					display: flex;
					flex-direction: row;
				}
			}
		}
	}
}
