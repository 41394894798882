body.flavour-glitch {
  .glitch.local-settings__navigation {
    min-width: 64px;
    .glitch.local-settings__navigation__item {
      display: flex;
      align-items: center;
      .text-icon-button:hover {
        background: transparent;
      }
    }
  }

  &.skin-default {
    &.layout-single-column {
      .navigation-panel {
        .column-link {
          @media screen and (max-width: 620px) {
            align-items: start !important;
            justify-content: center;
          }
          svg {
            display: inline;
          }
        }
      }
    }

    &.layout-multiple-columns {
      .column-header__setting-btn {
        justify-content: end;
      }
    }

    .notification__filter-bar {
      button {
        padding: 8px 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .doodle-modal__action-bar {
      background: #F0F0F0;
    }
    .status__info {
      padding-bottom: 0 !important;
      margin-bottom: 0 !important;
    }
    .status__prepend {
      padding-left: 38px !important;
      margin: 0 !important;
      width: calc(100% - 38px) !important;
      svg {
        width:1em !important;
        height:1em !important;
      }
      span {
        display: inline !important;
        padding: 0 !important;
        overflow:hidden;
        word-wrap: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  @media screen and (max-width: 620px) {
    .glitch.modal-root__modal {
      width: calc(100% - 16px);
    }

    div[role=presentation] {
      backdrop-filter: blur(5px);
    }
  }
}

div.modal-root {
  &__container {
    .modal-root__modal {
      margin: 0;
      border: 0;
      border-radius: 6px;

      .local-settings__page {
        padding: 8px 16px;
        h1, h2 {
          padding: 4px 12px;
          border-bottom: 2px solid var(--background-color);
        }
      }

      .local-settings__navigation {
        button, a {
          margin: 0;
          padding: 8px 12px;
          border: 0;
          display: block;
          line-height: 32px;
        }

        button.close {
          color: white;
        }

        @media screen and (width <= 620px) {
          button, a {
            justify-content: center;
          }
        }
      }
    }
  }
}
