@media screen and (width <= 1174px) {
  body:not(.skin-birdsiteui):not(.skin-birdsiteui-light) {

    .column-inline-form,
    .account__section-headline,
    .explore__search-results {
      border-left: 0 !important;
      border-right: 0 !important;
      border-radius: 0 !important;
    }

    @media screen and (width <= 600px) {

      .app-holder > div,
      .columns-area__panels {
        vertical-align: top !important;
        align-items: start !important;
      }

      .column-header__collapsible-inner {
        border: 0 !important;
      }

      .ui {
        display: block !important;
      }

      .ui__header {
        border: 0 !important;
        background: transparent !important;
        backdrop-filter: unset !important;
        position: fixed !important;
        left: 0 !important;
        top: 0 !important;
        right: 0 !important;
      }

      .tabs-bar__wrapper {
        top: 0px !important;
        padding-top: 55px !important;
        background: rgba($classic-base-color, .5) !important;
        backdrop-filter: blur(10px) !important;
      }

      .columns-area__panels__main {
        padding-bottom: 108px !important;
      }

      .columns-area.columns-area--mobile {
        border: 0 !important;
      }

      div > div > .ui > .columns-area {
        &__panels {
          display: flex !important;
          flex-direction: column !important;
          justify-content: start !important;

          > * {
            width: 100% !important;
          }

          .columns-area__panels__pane--navigational {
            position: fixed !important;
            z-index: 1000 !important;
            bottom: 0 !important;
            left: 0 !important;
            right: 0 !important;
            height: 54px !important;
            width: auto !important;

            .columns-area__panels__pane__inner {
              width: 100% !important;
              height:54px !important;
            }

            .navigation-panel {
              border-inline-start: unset !important;
              border-inline-end: unset !important;
              width: 100% !important;
              display: inline-flex !important;
              flex-basis: 0 !important;
              flex-direction: row !important;
              justify-content: center !important;
              flex-wrap: wrap !important;

              button,
              a {
                box-sizing:border-box;
                max-height: 54px !important;
                flex-grow: 1 !important;
              }

              a.column-link[href="/home"] {
                order: -9;
              }

              a.column-link[href="/public/local"] {
                order: -8;
              }

              a.column-link[href="/public"] {
                order: -7;
              }

              a.column-link[href="/conversations"] {
                order: -6;
              }

              a.column-link[href="/lists"] {
                order: -5;
              }

              a.column-link[href="/search"] {
                order: -4;
              }

              a.column-link[href="/lists"],
              a.column-link[href="/explore"],
              a.column-link[href="/settings/preferences"],
              a.column-link[href="/favourites"],
              a.column-link[href="/bookmarks"],
              .navigation-panel__legal {
                display: none !important;
              }
            }
          }
        }
      }
    }
  }
}
