* {
  scrollbar-color: unset !important;
}

::-webkit-scrollbar,
*::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important
}

::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 6px var(--theme-primary) !important;
  border: 2px solid transparent !important;
  border-radius: 5px !important;
}

::-webkit-scrollbar-thumb:hover,
*::-webkit-scrollbar-thumb:hover {
  box-shadow: inset 0 0 0 6px var(--theme-primary-bright) !important;
}
