@import 'sharlayan_base';

@import 'sharlayan_scroll';
@import 'sharlayan_modal';

@import 'sharlayan_other';

@import 'sharlayan_addon_default';
@import 'sharlayan_mobile';

.notification {
	&-group,
	&-ungrouped {
		padding: 8px;

		&>&__header {
			margin: 0;
		}

		& .status {
			padding: 0;
		}
	}
}
