/* css overrice cooking for 2024.03.15 update new css sets */
body.flavour-glitch {
  &.skin-default {
    --background-filter: blur(10px);
    --background-border-color: #{$gh-divider};
    background-color: $gh-select-background;

    .tabs-bar__wrapper {
      background: rgba($gh-select-background, 80%);
    }

    .status.collapsed .status__content:after {
      background: linear-gradient(transparent, $gh-select-background);
    }

    .drawer__inner,
    .drawer__inner__mastodon {
      background-color: transparent;
    }

    &.layout-multiple-columns {
      .column {
        padding: 0 !important;
        padding-inline-start: 0 !important;
        padding-inline-end: 0 !important;

        margin: 4px;
        margin-inline-start: 0px;
        margin-inline-end: 3px;

        .column-subheading,
        .column-header__wrapper {
          background-color: $gh-extra-black;
        }

        .column-header__collapsible,
        .column-header__Collapsible.collapsed,
        .scrollable {
          background-color: $gh-select-background;
        }
      }

      .notification__filter-bar {
        background-color: $gh-divider !important;
      }

      .compose-form__highlightable {
        border: 0 !important;
        background-color: $gh-select-background !important;

        textarea {
          background-color: $gh-select-background !important;
        }
      }

      .drawer {
        margin-inline-end: 1px;
      }

      .status.collapsed .status__content:after {
        background: linear-gradient(transparent, $gh-background)
      }

      .column > .column-header__wrapper > .column-header {
        border-bottom: 1px solid $gh-divider !important;
      }

      .column-header__buttons {
        .column-header__button {
          svg > path {
            fill: $classic-primary-color !important
          }

          &.active {
            svg > path {
              fill: white !important
            }
          }
        }
      }

      .search > .search__input,
      .drawer > .drawer__header,
      .drawer > .drawer__pager,
      .column-header__collapsible > .column-header__collapsible-inner,
      .column-header__wrapper > .column-header,
      .column > *,
      .scrollable {
        border: 0 !important;
      }

      .drawer > .drawer__header {
        background-color: $gh-header !important;
      }

      .search > .search__input {
        background-color: $gh-background !important;
      }

      .column-header__wrapper,
      .column > .scrollable {
        background-color: $gh-background !important;
      }

      .drawer {
        .drawer__pager {
          > .drawer__inner,
          > .drawer__inner > .drawer__inner__mastodon {
            background-color: $gh-divider !important;
          }
        }
      }
    }
  }
}
