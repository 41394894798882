@mixin fullwidth-gallery {
  &.full-width {
    margin-left: 0;
    margin-right: -14px;
    width: 100%;
    max-width: none;
    border-radius: 0;
  }
}

@mixin search-input() {
  outline: 0;
  box-sizing: border-box;
  width: 100%;
  box-shadow: none;
  font-family: inherit;
  background: var(--input-background-color);
  color: $darker-text-color;
  border-radius: 4px;
  border: 1px solid var(--background-border-color);
  font-size: 17px;
  line-height: normal;
  margin: 0;
}

@mixin search-popout() {
  background: $simple-background-color;
  border-radius: 4px;
  padding: 10px 14px;
  padding-bottom: 14px;
  margin-top: 10px;
  color: $light-text-color;
  box-shadow: 2px 4px 15px rgba($base-shadow-color, 0.4);

  h4 {
    text-transform: uppercase;
    color: $light-text-color;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  li {
    padding: 4px 0;
  }

  ul {
    margin-bottom: 10px;
  }

  em {
    font-weight: 500;
    color: $inverted-text-color;
  }
}
