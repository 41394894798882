$gh-blue:                       #58a6ff;
$gh-red:                        #ff7b72;
$gh-green:                      #3fb950;
$gh-yellow:                     #d29922;
$gh-magenta:                    #bc8cff;
$gh-cyan:                       #76e3ea;
$gh-desc:                       #8b949e;
$gh-white:                      #b1bac4;
$gh-verified:                   #3fb950;

$gh-extra-black:                #0d1117;
$gh-extra-black-bright:         #161b22;
$gh-extra-white:                #b1bac4;
$gh-extra-white-bright:         #b1bac4;
$gh-extra-gray:                 #6e7681;
$gh-extra-red:                  #ff7b72;
$gh-extra-red-bright:           #ffa198;
$gh-extra-green:                #3fb950;
$gh-extra-green-bright:         #56d364;
$gh-extra-yellow:               #d29922;
$gh-extra-yellow-bright:        #e3b341;
$gh-extra-blue:                 #58a6ff;
$gh-extra-blue-bright:          #79c0ff;
$gh-extra-magenta:              #bc8cff;
$gh-extra-magenta-bright:       #d2a8ff;
$gh-extra-cyan:                 #76e3ea;
$gh-extra-cyan-bright:          #b3f0ff;

$gh-blue-5:                     #1f6feb;
$gh-select-background:          #010409;
$gh-text:                       #c9d1d9;
$gh-link:                       #58a6ff;
$gh-verified:                   #3fb950;
$gh-description:                #8b949e;
$gh-background:                 #0d1117;
$gh-header:                     #161b22;
$gh-divider:                    #21262d;
$gh-primary-btn:                #238636;
$gh-primary-btn-hover:          #2ea043;
$gh-primary-btn-border:         rgba(240, 246, 252, .1);
$gh-primary-btn-hover-border:   rgba(240, 246, 252, .1);

body:not(.skin-birdsiteui):not(.skin-birdsiteui-light) {
  .status__prepend {
    padding-left: 38px !important;
    margin: 0 !important;
    width: calc(100% - 38px) !important;
    align-items: center;
    svg {
      width:1em !important;
      height:1em !important;
    }
    span {
      display: inline !important;
      padding: 0 !important;
      overflow:hidden;
      word-wrap: nowrap;
      text-overflow: ellipsis;
    }
  }

  /* 1174px */
  @media screen and (max-width: $no-gap-breakpoint)
  {
    .columns-area__panels__pane {
      background: $ui-base-color;
    }
  }
}

body.skin-birdsiteui,
body.skin-birdsiteui-light {
  &.layout-single-column {
    div.status__action-bar {
      display: flex;
      flex-basis: 0;
      gap: 8px;
      button, div {
        display: flex;
      }

      div.emoji-button>button.icon-button {
        width: 1.5em !important;
        min-width: 1.5em !important;
        max-width: 1.5em !important;
      }
    }

    @media screen and (max-width: 620px) {
      .navigation-panel {
        .column-link {
          align-items: center !important;
        }
      }
    }
  }
}

body.flavour-glitch {
  &.layout-multiple-columns {
    .drawer {
      .drawer__header {
        margin-bottom: 4px;
      }

      .search {
        margin-bottom: 4px;
      }
    }

    .column-header__collapsible-inner {
      display: flex;
      flex-direction: column-reverse;

      .column-header__advanced-buttons {
        padding: 8px;
        border-bottom: 1px solid lighten($ui-base-color, 8%);
      }
    }

    .column-header__collapsible.collapsed {
      margin-bottom: 1px;
    }

    .columns-area > .column {
      padding: 4px;
      padding-inline-start: 2px;
      padding-inline-end: 2px;
    }

    @media screen and (width >= 631px) {
      .drawer {
        padding: 4px;
        padding-inline-end: 2px;
        background-color: transparent;
      }
    }

    @media screen and (width >= 1440px) {
      .column {
        width: 400px;
      }
    }
  }


  &.layout-single-column {
    @media screen and (width >= 1174px) {
      .columns-area__panels__pane--compositional {
        padding-left: 10px;
      }

      .columns-area__panels {
        gap: 10px;
      }

      .tabs-bar__wrapper {
        padding: 0px !important;
        margin-top: 10px;
      }

      .link-footer {
        p {
          color: darken($ui-primary-color, 12%);

          margin-bottom: 8px;
          display: flex;
          flex-direction: column;

          a {
            color: darken($ui-primary-color, 12%);
          }

          span[aria-hidden] {
            display: none;
          }

          strong {
            display: block;
            padding: 8px;
          }
        }
      }
    }

    @media screen and (width <= 1174px) {
      .notification__filter-bar {
        border-left: 0;
        border-right: 0;
      }
    }

    @media screen and (width <= 890px) {
      .columns-area__panels__pane--navigational {
        .columns-area__panels__pane__inner {
          .navigation-panel {
            a {
              border: 0;
            }
          }
        }
      }
    }

    .compose-panel {
      .search {
        margin: 10px 0 10px 0 !important;
      }

      .compose-form {
        padding: 0 !important;
      }
    }

    .columns-area__panels {
      &__pane {
        > .tabs-bar__wrapper {
          padding: 0;
        }

        &__inner > .compose-panel {
          margin: 0;
        }
      }

      &__main {
        .columns-area {
          padding-bottom: 0 !important;

          &__panels {
            margin-top: 10px;
            margin-bottom: 10px;
          }

          .compose-form {
            padding: 0 !important;

            .navigation-bar {
              padding: 16px 16px 8px 16px;
            }

            &__highlightable {
              margin: 0 8px;
            }
          }
        }
      }
    }

    @media screen and (max-width: 700px) {
      .navigation-panel {
        .column-link {
          align-items: start !important;
          justify-content: center;
          svg {
            display: inline;
          }
        }
      }
    }
  }

  &.layout-multiple-columns {
    .column-header__wrapper {
      div.collapsed {
        margin-top: -1px;
      }
    }
  }
}

.notification-list {
  z-index: 10;
}

.icon-button.active.inverted {
  color: $white;
  svg > path {
    fill: $white;
  }
}

form.compose-form {
  padding: 8px !important;
  gap: 8px;

  & > [class^="compose-form"],
  & > [class^="compose-form"] > [class^="compose-form"],
  & > [class^="compose-form"] > [class^="compose-form"] > [class^="compose-form"] {
    gap: 8px;
  }

  .compose-form__footer {
    padding: 8px !important;
  }

  .compose-form__footer > .compose-form__dropdowns > div > button {
    padding: 2px 8px;
  }

  .button.secondary-post-button {
    display: flex;
    justify-content: center;
    align-content: center;
  }
}

/* fix search area*/
.search {
  > .search__input {
    padding: 6px 16px 6px 39px;
  }
}

.column {
  > .column-header__wrapper {
    section {
      padding: 8px;
    }
  }
}

.account__avatar {
  border-radius: 50%;
  overflow: hidden;
}

.announcements__item > .reactions-bar.reactions-bar--empty {
  display: flex;
}

/* notification fix */
.namelist {
  display: inline;
  a.status__display-name {
    display: inline
  }
}

.emoji-picker-dropdown__menu,
.dropdown-menu {
  backdrop-filter: blur(3px);
}

/* reactions */
.reactions-bar {
  padding: 0 !important;
  margin-bottom: 8px !important;
  gap: 4px;

  &__item {
    &__emoji {
      display: flex;
    }
  }
}

.reactions-bar--empty {
  display: none;
}

div.status__wrapper {
  aside.status__prepend {
    align-items: center;
  }
}

.status {
  &__info {
    &__icons {
      .text-icon {
        font-size: 11px;
      }

      /* public */
      svg.icon.icon-globe {
        color: $gh-blue;
      }

      /* unlisted */
      svg.icon.icon-unlock {
        color: $gh-green;
      }

      /* follower */
      svg.icon.icon-lock {
        color: $gh-yellow;
      }

      /* direct */
      svg.icon.icon-envelope {
        color: $gh-red;
      }

      /* threaded */
      svg.icon.icon-comment {
        color: $gh-magenta;
      }

      /* threaded */
      svg.icon.icon-video-camera,
      svg.icon.icon-picture-o {
        color: $gh-white;
      }
    }
  }

  &__wrapper {
    .status {
      padding: 8px;

      .status__action-bar {
        gap: 8px
      }

      .status__action-bar,
      .status__content,
      .reactions-bar,
      .status-card,
      .content-warning,
      .hashtag-bar,
      .status__content__read-more-button,
      .media-gallery {
        & {
          margin-inline-start: 52px;
          width: calc(100% - 52px);
        }
      }

      .status__content__read-more-button {
        padding: 5px;
        border: var(--background-border-color);
        border-radius: 5px;
      }

      .content-warning {
        margin-top: 8px;
      }
    }
  }

  &__info {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }

  &__action-bar {
    button.button {
      display: inline-flex;
      margin: 0 !important;
      padding: 0 !important;
    }

    div.emoji-picker-dropdown {
      color: inherit;
      display: inline-flex;
      min-width: 1em;
      text-align: left;

      button.button {
        display: inline-flex;
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  }
}

.notification__filter-bar {
  button {
    padding: 8px 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

:root {
  --theme-primary: #{$classic-highlight-color};
  --theme-primary-bright: #{lighten($classic-highlight-color, 8%)};
}
